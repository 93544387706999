/* You can add global styles to this file, and also import other style files */
@media (min-width: 37.56em){
    .what-you-will-learn--what-will-you-learn--1nBIT {
        padding-bottom: 1.6rem;
    }
    }
    .component-margin {
        margin-bottom: 3.2rem;
    }
    .what-you-will-learn--what-will-you-learn--1nBIT .what-you-will-learn--title--2ztwE {
        margin: 5px;
    }
    .what-you-will-learn--what-will-you-learn--1nBIT ul{
        list-style: circle;
    }
    .what-you-will-learn--what-will-you-learn--1nBIT li{
        margin: 5px;
    }
    .what-you-will-learn--what-will-you-learn--1nBIT li{
        margin: 5px;
    }
    @media (min-width: 73.75em){
     p,  h1,  h2,  h3,  h4,  h5,  h6,  ul,  ol {
        max-width: 118.4rem;
    }
    }
     p,  h1,  h2,  h3,  h4,  h5,  h6,  ul,  ol {
        max-width: 108.4rem;
    }
    .ud-heading-xl {
        font-family: udemy sans,-apple-system,BlinkMacSystemFont,Roboto,segoe ui,Helvetica,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: -.02rem;
        font-size: 1.5rem;
        text-align: center;
        max-width: 56em;
    }
    .what-you-will-learn--what-will-you-learn--1nBIT .what-you-will-learn--content-spacing--3n5NU {
        margin: 0 2.4rem;
    }
    .show-more-module--container--2QPRN {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .show-more-module--container--2QPRN span[data-checked=checked]~.show-more-module--content--cjTh0 {
        overflow: visible;
        max-height: none!important;
    }
    .show-more-module--content--cjTh0 {
        overflow: hidden;
        position: relative;
        width: 100%;
    }
    
    @media (min-width: 67.5em){
    .in-page-offset-anchor {
        top: -13.7rem;
    }
    }
    
    @media (min-width: 37.56em){
    .in-page-offset-anchor {
        top: -5.9rem;
    }
    }
    .in-page-offset-anchor {
        position: relative;
        top: -5.9rem;
    }
    @media (min-width: 67.5em){
    .in-page-offset-anchor {
        top: -7.7rem;
    }
    }
    @media (min-width: 37.56em){
    .in-page-offset-anchor {
        top: 0;
    }
    }
    .in-page-offset-anchor {
        position: relative;
        top: -4.4rem;
    }
    .ud-block-list .ud-block-list-item-neutral {
        color: #1c1d1f;
    }
    .ud-block-list .ud-block-list-item-tight {
        padding: 0.4rem 0;
    }
    .ud-block-list .ud-block-list-item {
        padding: 0.8rem 0;
    }
    .ud-block-list .ud-block-list-item {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: auto;
        text-align: left;
        letter-spacing: normal;
        white-space: normal;
    }
    .ud-text-sm {
        font-family: udemy sans,sf pro text,-apple-system,BlinkMacSystemFont,Roboto,segoe ui,Helvetica,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
        font-weight: 400;
        line-height: 1.4;
        font-size: 1.4rem;
    }
    .ud-block-list-item-icon+.ud-block-list-item-content {
        margin-left: 1.6rem;
    }
    .ud-block-list-item-small .ud-block-list-item-icon, .ud-block-list-item-small .ud-block-list-item-content {
        min-height: 1.96rem;
    }
    .ud-block-list-item-content {
        flex: 1;
        min-width: 1px;
    }
    .ud-block-list-item-small .ud-block-list-item-icon, .ud-block-list-item-small .ud-block-list-item-content {
        min-height: 1.96rem;
    }
    .ud-icon-color-neutral {
        color: #1c1d1f;
    }
    .ud-icon-xsmall {
        width: 1.6rem;
        height: 1.6rem;
    }
    .ud-icon {
        fill: currentColor;
        display: inline-block;
        flex-shrink: 0;
    }
    @media (min-width: 37.56em){
.what-you-will-learn--objectives-list-two-column-layout--rZLJy {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-direction: row;
}
}
.ud-unstyled-list {
    list-style: circle;
    margin: 0;
    padding: 0;
    max-width: none;
}
@media screen and (min-width: 37.56em){
.paid-course-landing-page__body {
    padding-top: 3.2rem;
}
}
@media screen and (min-width: 73.75em){
 .paid-course-landing-page__body {
    max-width: 118.4rem;
}
}
.paid-course-landing-page__body {
    margin: 0 auto;
    max-width: 108.4rem;
}
@media (min-width: 73.75em){
   .course-landing-page__main-content {
    max-width: 70rem;
}
}
@media (min-width: 67.5em){
   .course-landing-page__main-content {
    max-width: 70rem;
    /* margin-left: 4.8rem;
    margin-right: 4.8rem; */
}
}
   /* .course-landing-page__main-content {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
} */
.what-you-will-learn--objective-item--3b4zX{
    font-size: medium;
}
.dt {
    float: left;
    margin-right: 10%;
    max-width: 40%;
    list-style: circle;
    text-align: initial;
  }
  
  .dd {
    margin-left: 50%;
    margin-bottom: .5em;
    list-style: circle;
    text-align: initial;
  }

  /* nav bar li css start */
   .link-list {
    list-style-type: none;
    display: flex;
    margin-right: 25px;
    margin-top: 7px;
    
  }
  
  .link-list li {
    margin-right: 10px;
    font-weight: 600;
  }
  
  .link {
    text-decoration: none;
  }
  @media (max-width: 900px) and (max-width: 600px), (max-width: 1020px) {
    .link-list {
      flex-direction: column;
      flex-wrap: wrap;
      margin-left: 13px;
    }
  
    .link-list li {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  /* navbar li css in other pages (about,contact) start*/
 .link-list_inotherpages{
    list-style-type: none;
    display: flex;
    margin-right: 25px;
    color: white;
    margin-top: 7px;
  }
  .link-list_inotherpages li{
    margin-right: 10px;
    font-weight: 600;
  }
  .link {
    text-decoration: none;
  }
  @media (max-width: 900px) and (max-width: 600px), (max-width: 1020px) {
    .link-list_inotherpages {
      flex-direction: column;
      flex-wrap: wrap;
      margin-left: 13px;
      color: #1c1d1f;
    }
  
    .link-list_inotherpages li {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  /* navbar li css in other pages (about,contact) end */

  /* nav bar li css end */

  .what-you-will-learn--what-will-you-learn--1nBIT .container {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-align: start;
  }
  
  .what-you-will-learn--what-will-you-learn--1nBIT .item {
    box-sizing: border-box;
    padding: 10px;
  }
  
  @media (max-width: 767px) {
    .item {
      flex-basis: 100%; /* Each item takes 100% of the container width on smaller screens */
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .item {
      flex-basis: 50%; /* Each item takes 50% of the container width on medium-sized screens */
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .item {
      flex-basis: 100%; /* Each item takes 33.33% of the container width on large-sized screens */
    }
  }
  
  @media (min-width: 1200px) {
    .item {
      flex-basis: 50%; /* Each item takes 25% of the container width on extra-large screens */
    }
  }
  

  .course_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 101px;
    /* border: 1px dotted rgb(177, 10, 10); */
    font-size: 40px;
    /* width: 296px; */
    font-weight: 600;
  }
  

  /* navbar */
  /* ipad-pro */
  @media (min-width:1024px) and (max-width:1100px) {
   
    .contactmap{
      width: 800px;
      margin-left: auto;
      margin-right: auto;
  
    }
    .card{
    margin-right: 30px;
    }
  }
  

  